<template>
  <div class="bg-white box">
    <!-- table -->
    <vue-good-table
      class="vgt-no-border"
      mode="remote"
      :columns="columns"
      :rows="histories"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: total > 0,
      }"
      :sort-options="{
        enabled: false
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-column"
      >
        <span
          v-if="props.column.field === 'invoice'"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-start">
            <p class="mb-0">{{ props.column.label }}</p>
            <feather-icon
              v-if="guideToDownloadInvoiceSubscription"
              id="tooltip-learn-user-guide"
              icon="FileTextIcon"
              size="16"
              class="ml-25 text-success"
              style="min-width: 16px"
            />
            <b-tooltip
              target="tooltip-learn-user-guide"
              triggers="hover"
              variant="light"
            >
              How to download invoice?
              <a
                target="_blank"
                :href="guideToDownloadInvoiceSubscription"
              >{{ $t('payment.balanceAllocationTable.textViewDetail') }}</a>
            </b-tooltip>
          </div>
        </span>
      </template>
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === 'createdAt'"
          class="d-flex flex-column"
        >
          <span>{{ createdDate(props.row.createdAt ) }}</span>
        </div>

        <!-- Column: transaction type -->
        <span
          v-else-if="props.column.field === 'type'"
          class="text-nowrap"
        >
          {{ transactionTxt(props.row.type) }}
        </span>

        <!-- Column: status -->
        <span
          v-else-if="props.column.field === 'status'"
          class="text-nowrap text-uppercase"
        >
          <b-badge
            v-if="props.row.type === 'stripe_subscription' && props.row.status === 'done' && refundStatus(props.row.payload) === 'succeeded'"
            variant="light-secondary"
            class="font-12"
          >
            {{ $t("billingPage.refunded") }}
            <!--            <b-icon icon="arrow90deg-left" />-->
          </b-badge>
          <b-badge
            v-else-if="statusVariant(props.row.status)"
            :variant="statusVariant(props.row.status)"
            class="font-12"
          >
            {{ statusConvert(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: Balance -->
        <span v-else-if="props.column.field === 'amount'">
          {{ `${currencyFormat(props.row.amount)}` }}
        </span>

        <span v-else-if="props.column.field === 'note' && props.row.payload && props.row.payload.description">
          <feather-icon
            v-if="props.row.payload.description"
            :id="'popover-button' + props.row._id"
            class="cursor-pointer"
            icon="EyeIcon"
          />
          <b-popover
            v-if="props.row.payload.description"
            :target="'popover-button' + props.row._id"
            placement="top"
            triggers="hover"
            :variant="props.row.status === 'done' ? 'success' : 'error'"
          >
            <template #title>
              <span class="font-14">{{ props.row.status === 'done' ? 'Description' : 'Reason reject' }}</span>
            </template>
            <div v-html="props.row.payload.description" />
          </b-popover>
        </span>

        <span
          v-else-if="props.column.field === 'payload'"
        >
          {{ name(props.row) }}
        </span>

        <div v-else-if="props.column.field === 'invoice' && props.row.status === 'done'">
          <b-img
            id="btn-invoice"
            class="cursor-pointer"
            fluid
            :src="require('@/assets/images/icons/ic-download-invoice.svg')"
            @click="openModal(props.row)"
          />
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="total > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1 justify-content-end"
        >
          <div v-if="total > pageLength">
            <b-pagination
              :total-rows="total"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mt-md-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center my-1"
      >
        <b-img
          alt="No account"
          class="img"
          :src="require('@/assets/images/common/no-data-table.svg')"
        />
      </div>
      <!-- /no account image -->
    </vue-good-table>
    <!-- /table -->

    <download-billing-pdf-modal
      ref="modal-billing-download"
      :invoice-data="inVoiceSelected"
    />
    <download-billing-pdf-modal-pati
      ref="modal-billing-download-pati"
      :invoice-data="inVoiceSelected"
    />

  </div>
</template>
<script>
import {
  BPagination,
  BBadge,
  BSpinner,
  BImg,
  VBModal,
  BPopover,
  BTooltip,
} from 'bootstrap-vue'

// libs & cons
import { VueGoodTable } from 'vue-good-table'
import { createNamespacedHelpers } from 'vuex'

// mixins
import tableAccountHistoryMixin from '@/mixins/tableAccountHistoryMixin.vue'
import { STATUS_PLAN, TRANSACTION_TYPE } from '@/constants'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'
import DownloadBillingPdfModal from './DownloadBillingPdfModal.vue'
import DownloadBillingPdfModalPati from './DownloadBillingPdfModalPati.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    DownloadBillingPdfModal,
    DownloadBillingPdfModalPati,
    BImg,
    BBadge,
    BPagination,
    BPopover,
    BSpinner,
    VueGoodTable,
    BTooltip,
  },
  mixins: [tableAccountHistoryMixin, envMixin],
  data() {
    return {
      inVoiceSelected: {},
    }
  },

  computed: {

    ...mapGetters(['listBillingHistory', 'message', 'status', 'loading']),

    columns() {
      return [
        {
          label: this.$t('billingPage.textPlan'),
          field: 'payload',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('billingPage.textSubscriptionDate'),
          field: 'createdAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('balance.textAmount'),
          field: 'amount',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
          width: '190px',
        },
        {
          label: this.$t('balance.textStatus'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass: 'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.balanceTransactionsTable.textDownloadInvoice'),
          field: 'invoice',
          thClass: `p-1 border-left-0 border-right-0 header-table-mf ${this.hasShowInvoice && !this.isExclusivePartner ? '' : 'd-none'}`,
          tdClass: `p-1 border-left-0 border-right-0 align-middle ${this.hasShowInvoice && !this.isExclusivePartner ? '' : 'd-none'}`,
        },
      ]
    },

    histories() {
      return this.listBillingHistory.content
    },

    total() {
      return this.listBillingHistory.paging.total || 0
    },

    name() {
      return data => {
        if (data.payload && data.payload.lines && data.payload.lines.data && Array.isArray(data.payload.lines.data)) {
          return data.payload.lines.data[data.payload.lines.data.length - 1].plan?.nickname || '--'
        }
        return data.invoiceId
      }
    },

    statusConvert() {
      const currentStatus = {
        [STATUS_PLAN.DONE]: this.$t('billingPage.statusDone'),
        [STATUS_PLAN.REJECTED]: this.$t('billingPage.statusRejected'),
        [STATUS_PLAN.PENDING]: this.$t('billingPage.statusPending'),
      }

      return status => currentStatus[status]
    },

    refundStatus() {
      return payload => payload?.refundResponse?.status
    },

    // feeTxt() {
    //   return data => data.type === TRANSACTION_TYPE.RECHARGE_USER_BALANCE && data.payload
    // },
  },

  created() {
    this.fetchHistory()
  },

  methods: {
    ...mapActions(['getHistory']),
    async fetchHistory() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        types: [TRANSACTION_TYPE.STRIPE_SUBSCRIPTION],
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.statusSelected.value && { status: this.statusSelected.value }),
      }
      await this.getHistory({
        type: 'subscription',
        params,
      })
    },

    resetData() {
      this.currentPage = 1
      this.searchTerm = ''

      this.sortTerm = {
        field: '',
        type: '',
      }

      this.pageLength = 10
      this.statusSelected = {
        name: 'All status',
        value: '',
      }

      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null

      this.fetchHistory()
    },

    openModal(data) {
      this.inVoiceSelected = data
      if (this.isPATIPlatform) {
        this.$refs['modal-billing-download-pati'].openModal()
      } else {
        this.$refs['modal-billing-download'].openModal()
      }
    },
  },

}
</script>

<style lang="scss" scoped>

  .box{
    border-radius: 32px;
  }

  .reason {
    width: 300px;

    &-message {
      border: 1px solid #e0e0e5;
      border-radius: 4px;
      padding: 10px;
      margin-top: 5px;
    }
  }
</style>
